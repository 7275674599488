//#region Imports

import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NavbarEnum } from '../../../models/enums/navbar.enum';

//#endregion

export type StackActionItem = {
  id: string;
  action: () => Promise<void>;
};

export type StackUrlItem = {
  url: string;
};

@Injectable({
  providedIn: 'root',
})
export class StackActionService {

  //#region Constructor

  constructor(
    private readonly router: Router,
  ) {}

  //#endregion

  //#region Private Properties

  public stackUrlItems: StackUrlItem[] = [];

  public stackActionItems: StackActionItem[] = [];

  public blockToAddUrl: boolean = false;

  private lastUrl: string = '';

  //#endregion

  //#region Public Methods

  public initializeActions(): void {
    this.addRouterSubscriptionToStackUrlItems();
  }

  public addStackUrl(stackUrl: StackUrlItem): void {
    this.stackUrlItems.push(stackUrl);

    if (this.stackUrlItems.length > 20)
      this.stackUrlItems.shift();
  }

  public addStackAction(action: StackActionItem): void {
    this.stackActionItems.push(action);
  }

  public getLastStackUrl(): StackUrlItem {
    return this.stackUrlItems[this.stackUrlItems.length - 1];
  }

  public popStackUrl(): StackUrlItem {
    return this.stackUrlItems.pop();
  }

  public popStackAction(): StackActionItem {
    return this.stackActionItems.pop();
  }

  public removeCustomActionById(actionId: string): void {
    const index = this.stackActionItems.findIndex(stackAction => stackAction.id === actionId);

    if (index !== -1)
      this.stackActionItems.splice(index, 1);
  }

  public removeAllCustomActionById(actionId: string): void {
    this.stackActionItems = this.stackActionItems.filter(stackAction => stackAction.id !== actionId);
  }

  public cleanStackAction(): void {
    this.stackActionItems = [];
  }

  public cleanStackUrl(): void {
    this.stackUrlItems = [];
  }

  //#endregion

  //#region Private Methods

  private addRouterSubscriptionToStackUrlItems(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart)
          this.lastUrl = this.router.url;

        if (event instanceof NavigationEnd) {
          if (this.isNavigatingToAnotherMainPage(this.router.url))
            return;

          const stackUrlItemToAdd: StackUrlItem = {
            url: this.lastUrl,
          };

          if (!this.blockToAddUrl && stackUrlItemToAdd.url !== '/')
            this.addStackUrl(stackUrlItemToAdd);
        }
      });
  }

  private isNavigatingToAnotherMainPage(newUrl: string): boolean {
    return this.isMainPage(this.lastUrl) && this.isMainPage(newUrl);
  }

  private isMainPage(url: string): boolean {
    const urlWithoutQueryparams = url.split('?')[0];
    const urlHasNestedPage = !!urlWithoutQueryparams.split('/')[3];
    if (urlHasNestedPage)
      return false;

    const page = urlWithoutQueryparams.split('/')[2];

    return Object.values(NavbarEnum).includes(page as NavbarEnum);
  }

  //#endregion

}
