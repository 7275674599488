//#region Imports

import {Location} from '@angular/common';
import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalController, Platform} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {ModalConfirmComponent, ModalConfirmProps} from '../../../modals/modal-confirm/modal-confirm.component';
import {RouterService} from '../../../services/router/router.service';
import {UserService} from '../../../services/user/user.service';
import {StackActionService} from './stack-action.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class BackButtonService implements OnDestroy {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly platform: Platform,
    private readonly location: Location,
    private readonly userService: UserService,
    private readonly activeRoute: ActivatedRoute,
    private readonly routerService: RouterService,
    private readonly modalController: ModalController,
    private readonly stackActions: StackActionService,
  ) {
  }

  //#endregion

  //#region Private Properties

  private backButtonSubscription: Subscription = null;

  //#endregion

  //#region Public Methods

  public ngOnDestroy(): void {
    this.backButtonSubscription?.unsubscribe();
  }

  public async initializeBackButton(): Promise<void> {
    this.stackActions.initializeActions();

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, async () => {
      await this.executeBackButtonAction();
    });
  }

  public async executeBackButtonAction(): Promise<void> {
    const stackActionItems = this.stackActions.stackActionItems;
    const stackUrlItems = this.stackActions.stackUrlItems;
    const isNotAtRegister = this.router.url.includes('login') && !this.router.url.includes('register');

    if (stackActionItems.length !== 0)
      return void await this.callStackAction();

    if (this.router.url.includes('start'))
      return void await this.closeApp();

    if ((this.router.url.includes('main/campaigns') && stackUrlItems.length === 0) || isNotAtRegister)
      return void await this.openModalToCloseTheApp();

    return void await this.navigateByStackUrl();
  }

  //#endregion

  //#region Private Methods

  private async callStackAction(): Promise<void> {
    const stackAction = this.stackActions.popStackAction();
    await stackAction.action();
  }

  private async navigateByStackUrl(): Promise<void> {
    let stackUrl = this.stackActions.popStackUrl();

    if (!stackUrl || stackUrl.url?.includes('quiz') || stackUrl.url?.includes('qrcode'))
      stackUrl = {url: 'main/campaigns'};

    this.stackActions.blockToAddUrl = true;
    await this.routerService.navigateBackwards(stackUrl.url, {animated: true, animationDirection: 'back'});
    this.stackActions.blockToAddUrl = false;
  }

  private async openModalToCloseTheApp(): Promise<void> {
    const props: ModalConfirmProps = {
      title: 'Deseja fechar o app?',
      confirmButtonText: 'Fechar',
      cancelButtonText: 'Cancelar',
      emphasisOn: 'cancel',
    };

    const modal = await this.modalController.create({
      component: ModalConfirmComponent,
      cssClass: 'confirm-modal',
      componentProps: {props},
    });

    await modal.present();

    const {data: exitApp} = await modal.onDidDismiss();

    if (!exitApp)
      return;

    await this.closeApp();
  };

  private async closeApp(): Promise<void> {
    await navigator['app'].exitApp();
  }

  //#endregion

}
