/**
 * Converte REM para Pixels
 *
 * * @param rem Quantidade em REM
 */
export function convertRemToPixels(rem: number): number {
  const currentRemInPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return rem * currentRemInPx;
}

export function isNullOrUndefined(subject: unknown): boolean {
  return subject === null || subject === undefined;
}

/**
 * @example ....('field.subField', content) -> return content.field.subField
 */
export function getObjectDeepProperty<T>(fieldPath: string, content: T): any {
  const fields = fieldPath.split('.');

  if (fields.length > 3)
    throw new Error('The maximum number of possible fields is three');

  while (fields.length > 0)
    content = content[fields.shift()];

  return content;
}
