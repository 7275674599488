export const storageKeys = {
  token: 'JWT_TOKEN',
  userInformation: 'USER_INFORMATIONS',
  language: 'LANGUAGE',
  keepConnected: 'KEEP_CONNECTED',
  campaigns: 'CAMPAIGNS',
  products: 'PRODUCTS',
  completedMissions: 'COMPLETED_MISSIONS',
  userProducts: 'USER_PRODUCTS',
  usedPoints: 'USED_POINTS'
} as const;
