//#region Imports

import { routes } from './routes';
import { storageKeys } from './storage';

//#endregion

export const environment = {
  production: false,
  keys: storageKeys,
  configuration: {
    routeToRedirectWhenAuthenticated: '/main/campaigns',
    routeToRedirectWhenUnAuthenticated: '/start',
  },
  api: {
    baseUrl: 'https://api-dev.tintimvantagens.com.br',
    routes,
  },
  firebaseEvents: {
    appClose: 'app_close',
  },
};
